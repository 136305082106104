import Image from 'next/image';
import { Toaster as SonnerToaster } from 'sonner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';

import SuccessImage from '../../../assets/images/modal/tick.svg';
import loadingImage from '../../../assets/images/modal/loading.gif';

import styles from './Toaster.module.scss';

function Toaster() {
  return (
    <SonnerToaster
      position="top-center"
      className={styles.toaster}
      visibleToasts={1}
      toastOptions={{
        classNames: {
          toast: styles.toast,
          icon: styles.icon,
          content: styles.content,
          title: styles.title,
          error: styles.error,
        },
      }}
      icons={{
        success: <SuccessImage />,
        loading: <Image src={loadingImage} height={72} width={72} alt="loader" />,
        error: <FontAwesomeIcon icon={faCircleXmark} style={{ fontSize: '72px' }} />,
      }}
      dismissible={false}
      expand
    />
  );
}

export default Toaster;
